import React from "react"
import styled from "styled-components"
import Notifications from "../../images/notifications.png"
import Security from "../../images/security.png"
import Compatibility from "../../images/compatibility.png"
import Automate from "../../images/automate.png"
import Voice from "../../images/vc.png"
import Compliant from "../../images/fcc.png"
import { Link } from "gatsby"

import { Section, Container } from "../global"
import GatsbyImage from "gatsby-image"

const Features = () => (
  <Section id="features">
    <StyledContainer>
      <Subtitle>Features</Subtitle>
      <SectionTitle>Simple and secure technology</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
        <img src={Notifications} alt="Notificaitons" style={{width: "60px"}}/>
          <FeatureTitle>Notifications</FeatureTitle>
          <FeatureText>
            Receive notifications (SMS, push or email) on your mobile deivce based on custom triggers.
            <Link to="/service/">Check service plans</Link>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Security} alt="Security" style={{width: "80px"}}/>  
          <FeatureTitle>Security</FeatureTitle>
          <FeatureText>
            Your data and device operation is secure with amazon web services highest IT security standards.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Compatibility} alt="Compatibility" style={{width: "100px"}}/>
          <FeatureTitle>Compatible</FeatureTitle>
          <FeatureText>
            Compatible with most major garage door openers in the market. 
            <Link to="/compatibility/">Check compatibility</Link>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Automate} alt="Automate" style={{width: "120px"}}/>
          <FeatureTitle>Automation</FeatureTitle>
          <FeatureText>
           Create smart automated workflows and triggers with <a href="https://ifttt.com/">IFTTT</a>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Voice} alt="Voice Control" style={{width: "100px"}}/>
          <FeatureTitle>Voice Control</FeatureTitle>
          <FeatureText>Use Alexa, Google Home or Siri to operate your garage door.</FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Compliant} alt="Compliant" style={{width: "130px"}}/>
          <FeatureTitle>Compliant</FeatureTitle>
          <FeatureText>
            Device compliant with FCC and IC standards.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 800px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`
