import React from "react"
import styled from "styled-components"
import Security from "../../images/security.png"
import Product1 from "../../images/product1.png"
import App1 from "../../images/app1.png"
import Install1 from "../../images/Install1.png"

import { Link } from "gatsby"

import { Section, Container } from "../global"


function Features(props) {
    console.log("Props: ", props);
    return (
    <Section id="product">
    <StyledContainer>
      <Subtitle>Product</Subtitle>
      <SectionTitle>Easy to use and install</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Powerful technology</FeatureTitle>
          <FeatureText>
             No gateway required ! Long range FCC/IC approved WiFi module that uses your home standard 2.4 GHz broadband WiFi router.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Product1} alt="Product" style={{width: "100%"}}/>  
          <FeatureTitle></FeatureTitle>
          <FeatureText></FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Easy to install</FeatureTitle>
          <FeatureText>
             Accurate and reliable door magnetic switch. High quality shielded wires with attachment hardware. Plug and play connectors. 
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Install1} alt="Install" style={{width: "100%"}}/>
          <FeatureTitle></FeatureTitle>
          <FeatureText></FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Easy to Use</FeatureTitle>
          <FeatureText>
             Simple and secure app. Configure notifications, operate garage door remotely, monitor activity.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={App1} alt="App" style={{width: "75%"}}/>  
          <FeatureTitle></FeatureTitle>
          <FeatureText></FeatureText>
        </FeatureItem>

         </FeaturesGrid>
        
           <LearnMoreContainer>
           <Link to="/instructions/">
          Learn More
           </Link>
           </LearnMoreContainer>
    </StyledContainer>
  </Section>
)}

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 800px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 0px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  text-align: center;
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`
const LearnMoreButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

const LearnMoreContainer = styled(Container)`
  font-weight: 500;
  font-size: 24px;  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0 20px;
`
